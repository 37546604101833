import { Injectable, InjectionToken, Inject } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { map, catchError, finalize, timeout } from 'rxjs/operators';
import { LoaderService } from '../../components/loader/loader.service';
import { StorageService } from '../storage/storage.service';
import { NO_INTERNET, INTERNAL_SERVER_ERROR } from '../../constants/messages';
import { ToastService } from '../../components/toast-notification/toast.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private authService: AuthService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private storeService: StorageService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<boolean>> {
    const timeoutValue = Number(req.headers.get('timeout')) || this.defaultTimeout;

    const authReq = req.clone({ setHeaders: this.authService.authorization(req.url) });

    return next.handle(authReq).pipe(
      timeout(timeoutValue),
      map(event => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
        return event;
      }),

      catchError((error: HttpErrorResponse) => {
        this.errorState(error);
        return throwError(error.error ? error.error : error);
      }),

      finalize(() => {
        this.loaderService.hide();
      }),
    );
  }
  unAuthorizesStatusCase = [401, 440, 498];
  errorState(error: HttpErrorResponse): void {
    const errorMessage = error.error ? error.error.message : error.message;
    this.loaderService.hide();
    if (error.status == 0) {
      if (navigator.onLine) {
        this.toastService.error({ msg: INTERNAL_SERVER_ERROR });
        return;
      }
      this.toastService.error({ msg: NO_INTERNET });
      return;
    }

    if (this.unAuthorizesStatusCase.includes(error.status)) {
      this.toastService.error({ msg: errorMessage });
      this.storeService.logout();
      return;
    }
    this.toastService.error({ msg: errorMessage });
  }
}
