export const OFFLINE = 'Connection lost! You are not connected to Internet';
export const ONLINE = 'Back to online';
export const NO_INTERNET = 'No Internet Connection';
export const TIME_OUT = 'Connection timed out. Please retry';
export const INTERNAL_SERVER_ERROR = "Couldn't connect to the server. Please try again";
export const INVALID_ID_ERROR = 'Invalid ID';
export const VERIFICATION_CODE = 'Please enter verification code';
export const SOMETHING_WRONG = 'Something went wrong, Please try again later';
export const NO_ACTION = "You can't perform this action";

export const ACCOUNT_ERROR_MESSAGES = {
  NAME_REQ: 'Please enter name',
  FIRST_NAME_REQ: 'Please enter first name',
  LAST_NAME_REQ: 'Please enter last name',
  MIN_NAME_REQ: 'Please enter atleast 3 characters',
  MOBILE_REQ: 'Please enter phone number',
  INVALID_MOBILE: 'Please enter a valid phone number',
  MIN_MOBILE: 'Please enter atleast 10 digit phone number',
  PASSWORD_REQ: 'Please enter password',
  INVALID_PASSWORD:
    'Password must be at least 8 characters long, includes number, upper ,lower case letters and 1 special characters @,%,&,* only.',
  NO_SPACE_PASSWORD: "Password can't start or end with a blank space",
  EMAIL_REQ: 'Please enter email',
  INVALID_EMAIL: 'Please enter a valid email',
  ONLY_ALPHABET: 'Only alphabets are allowed',
  IMAGE_TYPE_AND_SIZE_MSG: 'Max 2 MB size and supported JPEG, PNG, JPG only',
};

export const PASSWORD_ERROR_MESSAGES = {
  OLD_PASSWORD_REQ: 'Please enter old password',
  INVALID_OLD_PASSWORD:
    'Password must be at least 8 characters long, includes number, upper ,lower case letters and 1 special characters @,%,&,* only.',
  NO_SPACE_OLD_PASSWORD: "Old password can't start or end with a blank space",
  NEW_PASSWORD_REQ: 'Please enter new password',
  INVALID_NEW_PASSWORD:
    'Password must be at least 8 characters long, includes number, upper ,lower case letters and 1 special characters @,%,&,* only.',
  NO_SPACE_NEW_PASSWORD: "New password can't start or end with a blank space",
  C_PASSWORD_REQ: 'Please enter confirm new password',
  INVALID_C_PASSWORD:
    'Password must be at least 8 characters long, includes number, upper ,lower case letters and 1 special characters @,%,&,* only.',
  NO_SPACE_C_PASSWORD: "Confirm new password can't start or end with a blank space",
  CONFORM_PASSWORD_MATCH_ERR: 'Password does not match',
  OLD_NEW_PASSWORD_MATCH_ERR: "Old & New password can't be the same",
  WRONG_OLD_PASSWORD: 'Please enter correct old password',
};

export const MAX_IMG_SELECTION = 4;
export const MIN_IMG_SELECTION = 1;
export const FILE_EXIST = 'Selected image is already exist';
export const MAX_IMG_SELECTION_ERR = (count = MAX_IMG_SELECTION) =>
  `You can select upto ${count} images only`;

export const S3_BUCKET_ERROR = 'Network Failure or Sync up your system clock';
export const BULK_EROR =
  'Please filter the athletes first on behalf of workout before performing the bulk operation';
export const ACTION_ERROR = (type: string) => `Please wait for ${type} action`;
export const TITLE_CONFIRMATION = (titleText: string) => `${titleText} Confirmation`;
export const MSSG_CONFIRMATION = (MssgText: string) => `Are you sure you want to ${MssgText}?`;

export const EDITOR_COMMON_MESSAGES = {
  CONTENT_REQ: 'Please fill some information about page',
  CMS_ACTION: (action: string) => `Content ${action} successfully`,
};

export const DATE_TYPES = {
  SHORT_TIME: 'h:mm a',
  MEDIUM_DATE: 'MMM d, yyyy',
  FULL_DATE: 'EE, MMM d, yyyy',
  VALIDITY_TIME: 'MMM d, y - hh:mm:ss a',
  DATE_WITH_TIME: 'MMM d, y - hh:mm a',
};

export const USER_STATUS_TABLE = {
  SEND: 'Sent',
  DRAFT: 'Draft',
};

export const SEND_NOTIFICATION_STATUS = [
  { name: 'Sent', value: 1 },
  { name: 'Draft', value: 2 },
];

export const NOTIFICATION_ERROR_MESSAGES = {
  TITLE_VALID_MSG: 'Please enter title',
  VALID_LINK_MSG: 'Please enter valid link',
  INVALID_NAME: 'Please enter a valid name',
  INVALID_TITLE: 'Please enter a valid title',
};

export const DIALOG_ERROR_MESSAGE = {
  Remove_Language_Title: 'Remove Language?',
  Remove_Language_Message: 'Are you sure you want to remove this language??',
  Deactivate_Title: 'Deactivate Language?',
  Deactivate_Message: 'Are you sure you want to make this language Inactive?',
  ResendNotificationTitle: 'Resend Notification',
  ResendNotificationMessage: 'Are you sure you want to resend this notification?',
  DeleteNotificationTitle: 'Delete Notification',
  DeleteNotificationMessage: 'Are you sure you want to delete this notification?',
  DeleteFaqTitle: 'Delete Faq',
  DeleteFaqMessage: 'Are you sure you want to delete this Faq?',
};

export const CONTENT_TYPES = {
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  OUR_VISION: 'PRIVACY_POLICY',
  ABOUT_US: 'ABOUT_US',
  FAQ: 'FAQ',
};

export const FAQ_ERROR_MESSAGES = {
  QUESTION_REQ: 'Please enter question',
  ORDER_REQ: 'Please enter question display order',
  INVALID_ORDER: 'Question display order should be greater than Zero',
  ANSWER_REQ: 'Please enter answer',
};
