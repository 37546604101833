import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ACCOUNT } from './constants/routes';
import { LogInGuard } from './services/guards/log-in/log-in.guard';
import { AuthGuard } from './services/guards/auth-guard/auth-guard.guard';

const routes: Routes = [
  { path: '', redirectTo: ACCOUNT, pathMatch: 'full' },
  {
    path: ACCOUNT,
    loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule),
    canActivate: [LogInGuard],
  },
  {
    path: '',
    loadChildren: () => import('./modules/layouts/layouts.module').then(m => m.LayoutsModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
