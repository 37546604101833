export interface ApiResponse<T> {
  statusCode: number;
  message?: string;
  data: T;
}

export interface ProfileDetails {
  fullName: string;
  email: string;
  image?: string;
}
export interface BreadcrumbConfig {
  path: string;
  label: string;
}
export interface ConfirmationModalConfig {
  title: string;
  img?: string;
  message: string;
  btn1: string;
  btn2: string;
}
export interface ToastConfig {
  msg: string;
  title?: string;
  type?: '_success' | '_info' | '_warning' | '_error';
  time?: number;
}

export interface UserProfile {
  name?: string;
  email?: string;
  title?: string;
  profilePicUrl?: string;
  userId?: string;
  accessToken?: string;
}

export interface LoinApiResponse {
  fullName: string;
  email: string;
  profilePicUrl: string;
  userId: string;
}

export interface RowData {
  id: number;
  index: number;
  data: {
    title: string;
    added_on: string;
    status: string;
    send_to: string;
    s_no: number;
  };
}

export interface filterEvent {
  apply: string;
}

export interface TableHeader {
  content?: string;
  faqContent?: string;
  link?: string;
  heading: string;
  key: string;
  type?: string;
  align?: string;
  sort?: boolean;
  action?: number[];
}

export interface PaginationSearch {
  previousPageIndex: number;
  pageIndex: number;
  pageSize: number;
  length: number;
}

export interface SortData {
  active: string;
  direction: string;
}

export interface SortByListing {
  active: string;
  direction: string;
}

export interface GetProfile {
  name: string;
  email: string;
  title: string;
}

export interface OtpDataInfo {
  email: string;
  otp: string;
}

export interface VerifyOprResponse {
  token: string;
}

export interface DashBoardData {
  totalCountUser: number;
  activeCountUser: number;
  refundedUser: number;
}

export interface FileSelection {
  base64Url?: string;
  realFile?: Blob;
  name?: string;
  profilePicUrl?: string;
}
export interface EditProfileFormValue {
  fullName: string;
  profileImage: string;
  email?: string;
  userId?: number;
}

export interface UpdateProfile {
  fullName: string;
  profileImage: string;
  email: string;
  userId: string;
}

export interface FleUploadResponse {
  data: { Location: string };
}

export interface ForgotPassword {
  email: string;
}

export interface PaginationSearch {
  previousPageIndex: number;
  pageIndex: number;
  pageSize: number;
  length: number;
}

export interface SortList {
  active: string;
  direction: string;
}
export interface NotificationList {
  title: string;

  added_on: string;

  status: string;

  send_to: string;
  type: string;
}
