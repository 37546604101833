import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DEFAULT_TOAST_CONFIG } from '../../constants';
import { ToastConfig } from 'src/app/constants/interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toast = new Subject<ToastConfig>();
  public notification = this.toast.asObservable();
  toastConfig: ToastConfig = DEFAULT_TOAST_CONFIG;

  success(toastConfig: ToastConfig) {
    if (toastConfig.msg) {
      toastConfig.type = '_success';
      this.toastConfig = { ...this.toastConfig, ...toastConfig };
      this.toast.next(this.toastConfig);
    }
  }

  info(toastConfig: ToastConfig) {
    if (toastConfig.msg) {
      toastConfig.type = '_info';
      this.toastConfig = { ...this.toastConfig, ...toastConfig };
      this.toast.next(this.toastConfig);
    }
  }

  warning(toastConfig: ToastConfig) {
    if (toastConfig.msg) {
      toastConfig.type = '_warning';
      this.toastConfig = { ...this.toastConfig, ...toastConfig };
      this.toast.next(this.toastConfig);
    }
  }

  error(toastConfig: ToastConfig) {
    if (toastConfig.msg) {
      toastConfig.type = '_error';
      this.toastConfig = { ...this.toastConfig, ...toastConfig };
      this.toast.next(this.toastConfig);
    }
  }
}
