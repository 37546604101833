import { Injectable } from '@angular/core';
import { StorageService } from '../../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private storageService: StorageService) {}

  authorization(requestUrl: string) {
    let token = this.storageService.token;
    if (requestUrl.includes('reset-password')) {
      token = this.storageService.resetToken;
    }

    return { Authorization: 'Bearer ' + token };
  }
}
