import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription, fromEvent, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  onlineEvent!: Observable<Event>;
  offlineEvent: Observable<Event> | undefined;
  subscriptions: Subscription[] = [];

  ngOnInit() {
    this.checkStatus();
  }

  /**
   * @ONLINE_OFFLINE Get the online/offline status from browser window
   */
  checkStatus() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      window.location.reload();
    });

    this.offlineEvent.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
