import { ACCOUNT, DASHBOARD } from './../../constants/routes';
import { Injectable } from '@angular/core';
import { userId } from '../../constants/storage-keys';
import { Router } from '@angular/router';
import { USER_INFO_API } from '../../constants/api-end-point';
import { HttpService } from '../http/http.service';
import { ApiResponse, UserProfile } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  profileDetail: UserProfile;
  resetToken: string;
  constructor(
    private _router: Router,
    private http: HttpService,
  ) {}

  setUserToken(token: string) {
    localStorage.setItem('session_token', token);
  }

  get token() {
    return localStorage.getItem('session_token');
  }

  get userId() {
    return atob(localStorage.getItem(userId) || '{}');
  }

  logout() {
    this.token;
    localStorage.removeItem('userId');
    localStorage.removeItem('session_token');
    setTimeout(() => {
      this.profileDetail = null;
    }, 1000); // this is used for avoid random reflection on side bar if logout
    this._router.navigate([ACCOUNT]);
  }

  loginSuccessfully(response: ApiResponse<UserProfile>) {
    this.setUserToken(response.data.accessToken);
    localStorage.setItem('userId', btoa(response.data.userId));
    this._router.navigate([DASHBOARD]);
  }

  setResetPasswordToken(token: string) {
    this.resetToken = token;
  }

  getProfileDetail(showLoader = true) {
    return new Promise<UserProfile>((resolve, reject) => {
      if (this.profileDetail) {
        resolve(this.profileDetail);
      } else {
        this.http
          .get<UserProfile>(USER_INFO_API, {}, showLoader)
          .toPromise()
          .then(
            response => {
              this.profileDetail = response.data;
              resolve(this.profileDetail);
            },
            error => {
              reject(error);
            },
          );
      }
    });
  }

  attachDeviceToken() {
    return (Date.now() + Math.floor(Math.random() * 1000000) + 1).toString();
  }

  randomDeviceId() {
    return window.navigator.userAgent.replace(/\D+/g, '');
  }

  getTimeZone() {
    const date = new Date();
    const offset = date.getTimezoneOffset() * -1;
    return offset * 60 * 1000;
  }
}
