const ADMIN_BASE = 'admin';
const STATIC_CONTENT_BASE = 'content';
const USER_SERVICE = 'userService/api/v1';
const USER_TYPE = `${USER_SERVICE}/${ADMIN_BASE}`;
const NOTIFICATION_BASE = 'notifications';
/**
 * @ACCOUNT_RELATED_END_POINTS
 */
export const LOGIN_API = `${ADMIN_BASE}/signin`;
export const LOGOUT_API = `${ADMIN_BASE}/profile/logout`;
export const FORGOT_PASSWORD_API = `${ADMIN_BASE}/forgot-password`;
// export const RESET_PASSWORD_API = (token: string) => `${ADMIN_BASE}/reset-password/${token}`;
export const RESET_PASSWORD_API = `${ADMIN_BASE}/reset-password`;
export const CHANGE_PASSWORD_API = `${ADMIN_BASE}/profile/change-password`;
export const VERIFY_OTP_API = `${ADMIN_BASE}/otp/verify`;
export const VERIFY_TOKEN_API = (token: string) => `${ADMIN_BASE}/token-verify/${token}`;
export const USER_INFO_API = `${ADMIN_BASE}/profile/details`;
export const USER_INFO_API_UPDATE = `${ADMIN_BASE}/profile/update`;
export const USER_IMAGE_API_UPDATE = `image/upload`;
/**
 * @DASHBOARD_RELATED_END_POINTS
 */
export const DASHBOARD_API = `admin-dashboard`;
/**
 * @CMS_RELATED_END_POINTS
 */
export const CMS_API = (type: string) => `${USER_TYPE}/${STATIC_CONTENT_BASE}/${type}`;
export const UPDATE_CMS_API = `${USER_TYPE}/${STATIC_CONTENT_BASE}`;
export const FAQ_API = `${STATIC_CONTENT_BASE}/faq`;

/**
 * @BROADCAST_NOTIFICATION_RELATED_END_POINTS
 */
export const NOTIFICATION_API = `${NOTIFICATION_BASE}`;
export const NOTIFICATION_DETAILS_API = (id: string) => `${NOTIFICATION_BASE}/${id}`;
export const NOTIFICATION_ACTION_API = `send-notification`;
export const DELETE_NOTIFICATION_API = (id: string) => `${NOTIFICATION_BASE}/${id}`;
