export enum FormErrorTypes {
  Required = 'required',
  Maxlength = 'maxlength',
  Minlength = 'minlength',
  Min = 'min',
  Max = 'max',
  Pattern = 'pattern',
  matchPassword = 'matchPassword',
}

export enum Timer {
  InitialTime = '00:00',
}

export enum StatusCodes {
  Status400 = 400,
  Status200 = 200,
}
