import { StorageService } from './../../storage/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ACCOUNT } from '../../../constants/routes';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    if (!this.storageService.token) {
      const urlTree: UrlTree = this.router.parseUrl(ACCOUNT);
      return urlTree;
    }

    return new Observable(observer => {
      this.storageService
        .getProfileDetail()
        .then(() => {
          observer.next(true);
        })
        .catch(() => {
          observer.next(false);
        });
    });
  }
}
