import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastService } from './toast.service';
import { ToastConfig } from '../../constants/interface';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  open = false;
  toastConfig: ToastConfig;

  constructor(private _toast: ToastService) {}

  ngOnInit() {
    this._toast.notification.pipe(takeUntil(this.destroy$)).subscribe((showState: ToastConfig) => {
      this.toastConfig = showState;
      this.open = true;
      setTimeout(() => {
        this.open = false;
      }, showState.time);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
