import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoaderService } from '../../components/loader/loader.service';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../constants/interface';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private loaderService: LoaderService,
  ) {
    this.apiUrl = environment.API_BASE_PATH;
  }

  post<T>(url: string, data: object = {}, loader = true): Observable<ApiResponse<T>> {
    if (loader) {
      this.loaderService.show();
    }
    return this.httpClient.post<ApiResponse<T>>(this.apiUrl + url, data);
  }

  put<T>(url: string, data?: unknown, loader = true): Observable<ApiResponse<T>> {
    if (loader) {
      this.loaderService.show();
    }
    return this.httpClient.put<ApiResponse<T>>(this.apiUrl + url, data);
  }

  delete<T>(
    url: string,
    query?: Record<string, string | number | boolean>,
    loader = true,
  ): Observable<ApiResponse<T>> {
    if (loader) {
      this.loaderService.show();
    }
    return this.httpClient.delete<ApiResponse<T>>(this.apiUrl + url, {
      params: query,
    });
  }

  patch<T>(
    url: string,
    data: unknown,
    option?: Record<string, string | number | boolean>,
    loader = true,
  ): Observable<ApiResponse<T>> {
    if (loader) {
      this.loaderService.show();
    }
    return this.httpClient.patch<ApiResponse<T>>(this.apiUrl + url, data, {
      params: option,
    });
  }

  get<T>(
    url: string,
    httpParams?: Record<string, string | number | boolean>,
    loader = true,
  ): Observable<ApiResponse<T>> {
    if (loader) {
      this.loaderService.show();
    }
    return this.httpClient.get<ApiResponse<T>>(this.apiUrl + url, {
      params: httpParams,
    });
  }

  getLocal(url: string) {
    return this.httpClient.get(url).pipe(catchError((err: HttpErrorResponse) => throwError(err)));
  }
}
