import { ToastConfig } from './interface';

export const DEFAULT_TOAST_CONFIG: ToastConfig = {
  title: '',
  msg: '',
  type: '_success',
  time: 5000,
};

export const BOX_TYPE = {
  IMAGE: 'ONLY_IMAGE',
};

export const FORGOT_PASSWORD_SCREEN = {
  Forgot: 1,
  Otp: 2,
  Reset: 3,
};
