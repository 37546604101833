export const ACCOUNT = 'account';
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';
export const PROFILE = 'profile';
export const EDIT = 'edit';
export const DETAILS = 'details';
//@DASHBOARD
export const DASHBOARD = 'dashboard';
//@NOTIFICATION
export const NOTIFICATION = 'notifications';
export const NOTIFICATION_LIST = 'list';
export const ADD_NOTIFICATION = 'add';
export const VIEW_NOTIFICATION = 'view';
export const EDIT_PROFILE = 'edit';
export const CHANGE_PASSWORD = 'change-password';
export const BASIC_DETAIL = 'basic-detail';

export const CMS = 'cms';
export const TERM_CONDITIONS = 'terms-&-conditions';
export const OUR_VISION = 'our-vision';
export const ABOUT_US = 'about-us';
export const FAQ = 'faq';
export const ADD_FAQ = 'add-faq';
